import React from "react";
import h2p from 'html2plaintext';
import Link from "gatsby-link";
import { graphql } from 'gatsby';
import TemplateWrapper from "../../components/template-wrapper";
import buildCategoryPath from "../../utils/category-path.js"

export default function ultraBiochem ({ data }) {
  let { nodes: posts } = data.allMarkdownRemark;
  console.log("Posts length: " + posts.length);
  const category = "ultrarunning";
  const site = "https://blogs.cybersym.com";
  const share = {
    url: site + "/ultrarunning/ultrarunning-biochem",
    title: "Biochemical Strategies for Ultrarunning",
    excerpt: "In recent years, long distance runners have seen significant improvements in training techniques, general nutritional approaches, and gear—particularly footwear. Nevertheless in races (especially at distances of 50 miles or more) most ultrarunners continue to confront the same old challenges: muscle cramps, fatigue, stomach distress, and blisters. Indeed the idea persists—both in the general public and amongst ultrarunners—that ultrarunning is all about suffering. This article is an attempt to dispel that myth by explaining how ultrarunners can avoid or minimize these challenges through an understanding of some relevant biochemistry. Certain approaches advocated here are not entirely new—what is new is an attempt to help ultrarunners grasp why these approaches work and the biochemical/performance consequences of ignoring them.",
    realContent: true
  };
  const accumulation = (
    posts.reduce( ( accum, post ) => {
      if (accum === undefined)
        accum = "";
      if (post === undefined)
        return accum;

      const tagsString = post.frontmatter.tags;

      return accum += " " + tagsString + " " + h2p(post.html) + " " + h2p(post.frontmatter.title);
    }, "")
  )

  return (
    <div className="palette--ultra-site" style={{ textAlign: "center" }}> 
    <TemplateWrapper headerImages={data.allFile.nodes} accumulation = {accumulation} category={"ultrarunning"} share={share} >   
      {posts
        .filter(post => post.frontmatter.title.length > 0)
        .map( ( post ) => {
          return (
            <div className="blog-post-preview" key={post.id}>
              <h2>
              <Link to={buildCategoryPath(category, post.fields.link)} dangerouslySetInnerHTML={{__html: post.frontmatter.title}}/>
              </h2>
              <h4>Written by {post.frontmatter.author} on {post.frontmatter.date}</h4>
              <h4>Tags: {post.frontmatter.tags}  
              {/* {
                post.frontmatter.tags && 
                post.frontmatter.tags
                .map( tag => (
                  <span key={tag.id}>
                    { counter++ > 0 ? `, ` : ' '}
                    {tag.name}
                  </span>
              ))} */}
              </h4>  
                <div dangerouslySetInnerHTML={{__html: post.html}} 
                    style={{
                        display: "inline",
                        padding: 0,
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                    }}
                />
            </div>
          );
        })}
    </TemplateWrapper>
    </div>
  );
};

export const pageQuery = graphql`
  query ultraBiochemQuery {
    allMarkdownRemark(
      filter: { frontmatter: { title: {regex: "/Ultrarunning/"}, categories: { eq: "biochemistry" } } },
      sort: { order: DESC, fields: [frontmatter___date] }) {
      nodes {
        id
        html
        fields {
          link
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          author
          categories
          tags
        }
      }
    }  
    allFile(filter: { sourceInstanceName: { eq: "images" }, 
    name: { regex: "/ultrarunning/" } }) 
    {       
      nodes {
        id
        relativePath
        extension
        name
        childImageSharp {
          gatsbyImageData( 
            layout: FULL_WIDTH, 
            placeholder: BLURRED
          )  
        }
      } 
    }
  }
`;


